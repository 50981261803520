import React from 'react';
import { Helmet } from 'react-helmet';

import MainLayout from '../layouts/Main';
import Head from '../components/Head';

import * as css from './test.module.css';

const Test = () => {
  return (
    <MainLayout>
      <Helmet
        bodyAttributes={{
          class: 'invert'
        }}
      />
      <Head title="Home" />
      <Type>
        <p>Heading 1</p>
        <h1 className={css.h1}>Our work</h1>
      </Type>
      <Type>
        <p>Heading 2</p>
        <h2 className={css.h2}>
          Into the A
          <br />
          Light
        </h2>
      </Type>
      <Type>
        <p>Heading 3</p>
        <h3 className={css.h3}>
          Into the
          <br />
          Light
        </h3>
      </Type>
      <Type>
        <p>Heading 4</p>
        <h4 className={css.h4}>
          Bridging artists and brands
          <br /> for immersive experiences
        </h4>
      </Type>
      <Type>
        <p>Text 1</p>
        <p className={css.txt1}>
          Vortic is the art world’s leading extended reality (XR) platform that
          offers customized and sustainable solutions for galleries to exhibit
          works of art using the most advanced AR and VR technologies.
        </p>
      </Type>
      <Type>
        <p>Text 2</p>
        <p className={css.txt2}>
          “....Galleries and Fairs are Turning to Virtual Reality to Connect
          with Collectors...”
        </p>
      </Type>
      <Type>
        <p>Text 3</p>
        <p className={css.txt3}>
          Vortic Curate is a content management system that allows galleries to
          engage collectors all over the world with specially curated virtual
          exhibitions and invite-only experiences.
        </p>
      </Type>
      <Type>
        <p>Text 3b</p>
        <p className={css.txt3b}>Capabilities</p>
      </Type>
      <Type>
        <p>Text 4</p>
        <p className={css.txt4}>
          3D Exhibitions
          <br />
          Curatorial Tours
          <br />
          AR allows collectors to place artwork on their walls
          <br />
          VR let users walk around the gallery or museum from the comfort of
          your own home
          <br />
          Proprietary VFX Rendering Pipeline
        </p>
      </Type>
      <Type>
        <p>Text 4b</p>
        <p className={css.txt4b}>The Art Newspaper</p>
      </Type>
      <Type>
        <p>Text 5</p>
        <p className={css.txt5}>
          231 Bowery,
          <br />
          New York, NY 10002
          <br />
          hello@superbright.me
        </p>
      </Type>
      <Type>
        <p>Text 5b</p>
        <p className={css.txt5b}>
          231 Bowery,
          <br />
          New York, NY 10002
          <br />
          hello@superbright.me
        </p>
      </Type>
      <div className={css.hinting}>
        <div className={css.org}>SUPERBRIGHT</div>
        <div className={css.updated}>SUPERBRIGHT</div>
      </div>
    </MainLayout>
  );
};

const Type = ({ children }) => {
  return (
    <div className={css.type}>
      <div className={css.typeInfo}>{children[0]}</div>
      <div className={css.typePreview}>{children[1]}</div>
    </div>
  );
};

export default Test;
