// extracted by mini-css-extract-plugin
export var h1 = "test-module--h1--nR0io";
export var h2 = "test-module--h2--3eqx1";
export var h3 = "test-module--h3--cK2s8";
export var h4 = "test-module--h4--1B7-x";
export var txt1 = "test-module--txt1--1gZof";
export var txt2 = "test-module--txt2--YVFCD";
export var txt3 = "test-module--txt3--2ZMPF";
export var txt3b = "test-module--txt3b--3DZkg";
export var txt4 = "test-module--txt4--1gzOI";
export var txt4b = "test-module--txt4b--1Fppj";
export var txt5 = "test-module--txt5--1Ixae";
export var txt5b = "test-module--txt5b--2I-c4";
export var hinting = "test-module--hinting--1_y2U";
export var org = "test-module--org--3UTAw";
export var updated = "test-module--updated--3nAy8";
export var type = "test-module--type--3TR0T";
export var typeInfo = "test-module--typeInfo--Z01ms";
export var typePreview = "test-module--typePreview--2SXia";